/**
 *  Check if a character is a letter
 * @param character
 */
export function isLetter(character: string) {
  const charCode = character.charCodeAt(0);
  if (charCode >= 65 && charCode <= 90) return true;
  if (charCode >= 97 && charCode <= 122) return true;
  return false;
}
