/**
 * Returns the country from the sale ref
 * @param saleRef
 */
export function getCountryFromSaleRef(saleRef: string) {
  const code = saleRef.substring(0, 2).toLowerCase();
  if (code === 'ma') return 'ma';
  if (code === 'mc') return 'mc';
  return 'fr';
}
